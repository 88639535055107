import Chat from '../Chat/Chat';
import Polls from '../Polls/Polls';
import './InteractivityLayer.css';

function InteractivityLayer({ userId, roomId }) {
  return (
    <div className="interactivity-layer-container">
      <Polls userId={userId} roomId={roomId} />
      <Chat userId={userId} roomId={roomId} />
    </div>
  );
}

export default InteractivityLayer;
