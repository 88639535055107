import { useEffect, useState } from 'react';
import PChat from '../../services/PChat';
import './Chat.css';
import Reactions from '../Reactions/Reactions';
import { v4 as uuidv4 } from 'uuid';
import { getToken } from '../../services/chat';

function Chat({ userId, roomId }) {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState(''); // New state variable for the input value
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [areReactionsVisible, setAreReactionsVisible] = useState(false);
  const [flyingReactions, setFlyingReactions] = useState([]);
  const [reactionsStats, setReactionsStats] = useState([]);

  const toggleChat = () => {
    setIsChatVisible(!isChatVisible);
  };

  const toggleReactions = () => {
    setAreReactionsVisible(!areReactionsVisible);
  };

  function handleReactionClick(reaction) {
    const id = uuidv4(); // Generate a unique ID for this reaction
    setFlyingReactions((prevReactions) => [...prevReactions, { id, reaction }]);
    setTimeout(() => {
      setFlyingReactions((prevReactions) => prevReactions.filter((r) => r.id !== id));
    }, 5000); // Remove the reaction after 5 seconds
  }

  useEffect(() => {
    const token = process.env.REACT_APP_CHAT_TOKEN || '';

    const room = roomId || 'wroom';
    const connectToChat = async () => {
      let authToken = token;
      if (!authToken) {
        authToken = await getToken(room);
      }
      console.log(authToken, userId, room);
      await PChat.connect(room, userId, authToken);
      PChat.on(PChat.Events.MESSAGE, (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
      });
      PChat.on(PChat.Events.REACTION, (reaction) => {
        handleReactionClick(reaction.reaction);
        setReactionsStats((prevStats) => {
          const isReactionAlreadyCounted = prevStats.some((stat) => stat._id === reaction.reaction);
          const updatedStats = prevStats.map((stat) => {
            if (stat._id === reaction.reaction) {
              return { ...stat, count: stat.count + 1 };
            }
            return stat;
          });
          if (!isReactionAlreadyCounted) {
            updatedStats.push({ _id: reaction.reaction, count: 1 });
          }
          return updatedStats;
        });
      });
      PChat.on(PChat.Events.REACTIONS_STATS, (stats) => {
        setReactionsStats(stats);
      });
      await PChat.getReactionStats();
    };

    if (room && userId) {
      connectToChat();
    }
  }, [userId, roomId]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      PChat.publish(inputValue);
      setInputValue('');
    }
  };

  return (
    <>
      <button className={`chat-button ${isChatVisible ? 'invisible' : ''}`} onClick={toggleChat}>
        Chat
      </button>
      <button className="reactions-button" onClick={toggleReactions}>
        Reactions {areReactionsVisible ? '<<' : '>>'}
      </button>
      <div className={`chat-container ${isChatVisible ? 'visible' : ''}`}>
        <button className={`close-button ${isChatVisible ? 'visible' : ''}`} onClick={toggleChat}>
          x
        </button>
        <h5>Chat Room</h5>

        <div className="messages">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.user.meta.username === userId ? 'self' : ''}`}>
              <div className="message-username">{message.user.meta.username}:</div>
              <div className="message-content">{message.content}</div>
            </div>
          ))}
        </div>
        <input
          className="chat-input"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          placeholder="Type your message here..."
        />
      </div>
      <Reactions areVisible={areReactionsVisible} reactionsStats={reactionsStats} />
      <div className="flying-reactions">
        {flyingReactions.map(({ id, reaction }) => (
          <span
            key={id}
            className="flying-reaction"
            // style={{
            //   transform: `translate(${Math.random() * 100 - 50}vw, ${Math.random() * 100 - 50}vh)`,
            // }}
          >
            {reaction}
          </span>
        ))}
      </div>
    </>
  );
}

export default Chat;
