import React, { useCallback } from 'react';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WT from '@sscale/wtsdk';

import { off, on, publish } from 'services/PubSub';
import { resetStore as resetCelebrityStore } from 'store/reducers/CelebrityReducer';
import { setSessionError, setParticipants, setJoinModalType } from 'store/reducers/SessionReducer';
import celebrityAPI from 'services/celebrity';

import Video from '../Video/Video';
import JoinModal from './components/JoinModal/JoinModal';
import Rooms from './components/Rooms/Rooms';
import Player from '../Player/Player';
import SessionError from './components/SessionError/SessionError';
import Participants from './components/Participants/Participants';
import './Celebrity.css';
import { Logger, Publisher } from '@sscale/cdnwebsdk';
import { getPublisherToken } from '../../services/cdn';
import Chat from '../Chat/Chat';
import InteractivityLayer from '../InteractivityLayer/InteractivityLayer';
import PollManager from '../Polls/PollManager';

const publisherLogger = Logger.get('Publisher');
publisherLogger.setLevel(Logger.DEBUG);

const audioContext = new AudioContext();
const audioDestination = audioContext.createMediaStreamDestination();
const audioSources = {};
const mixedStream = new MediaStream();

const urlParams = new URLSearchParams(window.location.search);
const roomId = urlParams.get('streamRoom') || 'wroom';

function prepareMixedStream({ videoTracks, audioTracks }) {
  if (videoTracks) {
    mixedStream.getVideoTracks().forEach((track) => {
      mixedStream.removeTrack(track);
    });
    videoTracks.forEach((track) => {
      mixedStream.addTrack(track);
    });
  }

  if (audioTracks) {
    mixedStream.getAudioTracks().forEach((track) => {
      mixedStream.removeTrack(track);
    });
    audioTracks.forEach((track) => {
      mixedStream.addTrack(track);
    });
  }
}

function getAudioStream() {
  // const gainNode = audioContext.createGain();
  // gainNode.connect(audioContext.destination);

  const videoElementsNodeList = document.querySelectorAll('video[id^="participant-"]');
  const videoElements = Array.from(videoElementsNodeList);
  const player = document.getElementById('player');

  if (player) {
    videoElements.push(player);
  }

  videoElements.forEach((video) => {
    const stream = video.srcObject;
    if (!audioSources[video.id]) {
      const audioSource =
        stream instanceof MediaStream
          ? audioContext.createMediaStreamSource(stream)
          : audioContext.createMediaElementSource(video);
      audioSource.connect(audioDestination);
      audioSources[video.id] = audioSource;
      // audioSource.connect(gainNode);
    }
  });

  return audioDestination.stream;
}

function Session() {
  const dispatch = useDispatch();

  const { isCelebrity, userName, constraints } = useSelector((state) => state.celebrity);
  const { participants, connectedClb } = useSelector((state) => state.session);

  const [roomHasCelebrity, setRoomCelebrityPresence] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [publisher, setPublisher] = useState(null);
  const [isCdnMuted, setIsCdnMuted] = useState(false);
  // const [roomsList, setRoomsList] = useState([]);
  // const [pageIsActive, setPageIsActive] = useState(true);

  const participantsRef = useRef(participants);
  const connectedClbRef = useRef(connectedClb);
  const makeCelebrityLocalRef = useRef();
  const roomHasCelebrityRef = useRef(roomHasCelebrity);

  useEffect(() => {
    if (isCelebrity !== null) {
      if (roomHasCelebrity) {
        return;
      }

      makeCelebrityLocalRef.current();
    }
  }, [isCelebrity]);

  useEffect(() => {
    participantsRef.current = participants;
  }, [participants]);

  useEffect(() => {
    roomHasCelebrityRef.current = roomHasCelebrity;
  }, [roomHasCelebrity]);

  useEffect(() => {
    connectedClbRef.current = connectedClb;
  }, [connectedClb]);

  useEffect(() => {
    WT.ErrorsListeners.onSessionError((error) => {
      dispatch(setSessionError('Unexpected error. Unable to join the room.'));
      makeCelebrityLocalRef.current();
    });

    WT.SessionListeners.onConnected(() => {
      connectedClbRef.current();

      let participants = [];

      WT.SessionListeners.onStreamCreated((params) => {
        const alreadyExists = participants.find((p) => p.participantId === params.participantId);

        if (alreadyExists) {
          const video = document.getElementById('participant-' + params.participantId);
          video.src = params.stream;
          // dispatch(setParticipants([...participants.filter(p => p.participantId !== params.participantId), { ...params }]))
        } else if (params.isCelebrity) {
          setRoomCelebrityPresence(params);
        } else {
          participants.push(params);
          dispatch(setParticipants([...participants]));
        }

        const audioSource = audioContext.createMediaStreamSource(params.stream);
        audioSource.connect(audioDestination);
        if (publisher) {
          console.log(`replacing audio  track`);
          publisher.replaceTrack(audioDestination.stream.getAudioTracks()[0]);
        }
      });

      WT.ParticipantListeners.onParticipantLeft(({ participantId }) => {
        if (roomHasCelebrityRef?.current && roomHasCelebrityRef?.current?.participantId === participantId) {
          setRoomCelebrityPresence(null);
        } else {
          participants = [...participants.filter((p) => p.participantId !== participantId)];
          dispatch(setParticipants(participants.filter((p) => p.participantId !== participantId)));
        }
      });
    });

    WT.ParticipantListeners.onParticipantMediaStreamChanged(publish.bind(null, 'onParticipantMediaStreamChanged'));
    WT.ParticipantListeners.onParticipantSpeaking(publish.bind(null, 'onParticipantSpeaking'));
    WT.ParticipantListeners.onParticipantStopSpeaking(publish.bind(null, 'onParticipantStopSpeaking'));

    function onParticipantMediaStreamChanged({ participantId, mediaType, mediaState }) {
      if (publisher) {
        switch (mediaType) {
          case 'VIDEO':
            const videoTrack = participantsRef.current
              .find((p) => p.participantId === participantId)
              ?.stream?.getVideoTracks()[0];
            if (videoTrack) {
              publisher.replaceTrack(videoTrack);
            }
            break;
          case 'AUDIO':
            const audioTrack = participantsRef.current
              .find((p) => p.participantId === participantId)
              ?.stream?.getAudioTracks()[0];
            if (audioTrack) {
              publisher.replaceTrack(audioTrack);
            }
            break;
        }
      }
    }
    on('onParticipantMediaStreamChanged', onParticipantMediaStreamChanged);

    return () => {
      off('onParticipantMediaStreamChanged', onParticipantMediaStreamChanged);
    };
  }, []);

  makeCelebrityLocalRef.current = useCallback(() => {
    dispatch(setParticipants([]));
    navigator.mediaDevices.getUserMedia(constraints).then((device) =>
      setRoomCelebrityPresence({
        stream: device,
        participantId: '1',
        participantName: userName,
        streamConstraints: constraints,
        local: true,
        preview: true,
      }),
    );
  }, [userName, constraints]);

  const stopLocalStream = () => {
    const tracks = roomHasCelebrityRef?.current?.stream?.getTracks();

    if (tracks && tracks.length > 0) {
      tracks[0].stop();
    }
  };

  useEffect(() => {
    return () => {
      stopLocalStream();
    };
  }, []);

  useEffect(() => {
    dispatch(setJoinModalType('CELEBRITY_JOIN_DATA'));
  }, []);

  useEffect(() => {
    let CDN_PREVIEW_INTERVAL_ID = null;
    const canvas = document.getElementById('cdn-preview');
    const ctx = canvas.getContext('2d');

    // Fill the canvas
    ctx.fillStyle = '#000000'; // Change this to the color you want
    ctx.beginPath();
    ctx.roundRect(0, 0, canvas.width, canvas.height, 8);
    ctx.fill();
    const padding = 24;
    const videoWidth = 200;
    const videoHeight = 150;

    const player = document.getElementById('player');

    const drawAvailableVideos = () => {
      const celebrityVideo = document.getElementById('participant-1');

      ctx.drawImage(player, 0, 0, canvas.width, canvas.height);
      if (celebrityVideo) {
        ctx.drawImage(celebrityVideo, padding, 2 * padding + 10, videoWidth, videoHeight);
      }
      const videos = document.querySelectorAll('video[id^="participant-"]');

      videos.forEach((video, index) => {
        let y = 2 * padding + 10 + index * (videoHeight + 5);
        ctx.drawImage(video, padding, y, videoWidth, videoHeight);
      });
      // player.requestVideoFrameCallback(drawAvailableVideos); // stopped working when video is paused
      // requestAnimationFrame(drawAvailableVideos); // stopped working when tab is inactive
    };

    // player.requestVideoFrameCallback(drawAvailableVideos);
    // requestAnimationFrame(drawAvailableVideos);

    // create a loop with 60 fps
    const interval = 1000 / 30;
    CDN_PREVIEW_INTERVAL_ID = setInterval(drawAvailableVideos, interval);
  }, []);

  // const syncRoomList = (ref) => {
  //   const getSessions = callable('getSessions');
  //
  //   const fetchSessions = async () => {
  //     try {
  //       try {
  //         const res = await getSessions();
  //         setRoomsList(res?.data || []);
  //       } catch (e) {
  //         // ignore
  //       }
  //
  //       ref.timerId = setTimeout(fetchSessions, 5000);
  //     } catch (e) {}
  //   };
  //
  //   fetchSessions();
  // };

  function logout() {
    WT.Session.disconnect();

    stopLocalStream();

    celebrityAPI.logout();

    setRoomCelebrityPresence(null);
    // setRoomsList([]);
    dispatch(setParticipants([]));
    dispatch(resetCelebrityStore());

    dispatch(setJoinModalType('CELEBRITY_JOIN_DATA'));
  }

  function handleMute() {
    console.log('handleMute', isCdnMuted, publisher);
    if (!isCdnMuted) {
      console.log('mute');
      publisher.muteAudio();
      setIsCdnMuted(true);
    } else {
      console.log('unmute');
      publisher.unmuteAudio();
      setIsCdnMuted(false);
    }
  }

  async function startCdnPublishing() {
    if (isPublishing) {
      window.location.reload();
      return;
    }
    try {
      const token = await getPublisherToken(roomId);
      const canvas = document.getElementById('cdn-preview');
      const stream = canvas.captureStream();
      const audioStream = getAudioStream();

      const videoTracks = stream.getVideoTracks();
      const audioTracks = audioStream.getAudioTracks();
      prepareMixedStream({ videoTracks, audioTracks });

      // const mixedStream = new MediaStream([...stream.getVideoTracks(), ...audioStream.getAudioTracks()]);
      const url = 'https://cdn.management.sceenic.co/v1/stream';
      if (!publisher) {
        const publisher = new Publisher(url, token);
        publisher.publish(mixedStream, 6000000, true);
        // collecting stats on publisher side causing some stuttering in canvas loop
        // publisher.startCollectingStats();
        // setTimeout(() => {
        //   publisher.stopCollectingStats();
        // }, 10000);
        setPublisher(publisher);
      }
      setIsPublishing(true);
    } catch (error) {
      console.error('Error getting publisher token', error);
    }
  }

  return (
    <div className="session-container is-celebrity-page">
      <div className="s-body">
        <div className="s-celebrity-container">
          <div className="s-celebrity-video">
            <Video participant={roomHasCelebrity} isControlled={true} isCelebrity={true} />
          </div>
          <button className="s-logout" onClick={logout} title={'Log out'} />
          <div className="s-celebrity-controls">
            <canvas className="canvas-cdn-preview" id="cdn-preview" width="1280" height="720" />
            <button className="button room-next-button" onClick={startCdnPublishing}>
              {isPublishing ? 'Stop' : 'Start'} Streaming
            </button>
            {/*<button className="button" onClick={handleMute}>*/}
            {/*  Mute*/}
            {/*</button>*/}
          </div>
        </div>

        <div className="s-activity-container">
          <div className="s-participants">
            <Participants />
          </div>

          <div className="s-player-container">
            <Player isLoggedIn={isCelebrity} isSyncRequired={!!participants.length} />
            {userName && <InteractivityLayer roomId={roomId} userId={userName} />}
          </div>
        </div>

        <div className="tools">
          <Rooms key={userName} isLoggedIn={isCelebrity} makeCelebrityLocal={makeCelebrityLocalRef.current} />
          <PollManager />
        </div>

        <JoinModal />

        <SessionError />
      </div>
    </div>
  );
}

export default Session;
