import React from 'react';
import './Reactions.css';
import PChat from '../../services/PChat';

function Reactions({ areVisible, reactionsStats }) {
  const reactions = ['👍', '❤️', '🔥', '😀', '😂', '😍', '😢', '👎']; // Add your reactions here

  const reactionData = {};
  reactionsStats.forEach((reaction) => {
    reactionData[reaction._id] = reaction.count;
  });
  function handleReactionClick(reaction) {
    PChat.sendReaction(reaction);
  }

  return (
    <div className={`reactions-container ${areVisible ? 'visible' : ''}`}>
      {reactions.map((reaction, index) => (
        <div className="reaction-wrapper" key={index}>
          <span className="reaction" onClick={() => handleReactionClick(reaction)}>
            {reaction}
          </span>
          <span className="counter">{reactionData[reaction] || 0}</span>
        </div>
      ))}
    </div>
  );
}

export default Reactions;
