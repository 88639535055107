import React, { useState, useEffect, useRef } from 'react';
import './PollManager.css';
import PChat from '../../services/PChat';

function PollManager() {
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState(['']);
  const [activePolls, setActivePolls] = useState([]);
  const inputsRef = useRef([]);

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleOptionChange = (index) => (event) => {
    const newOptions = [...options];
    newOptions[index] = event.target.value;
    if (index === options.length - 1 && event.target.value !== '') {
      newOptions.push('');
    }
    setOptions(newOptions);
  };

  const handleOptionBlur = (index) => (event) => {
    if (event.target.value === '') {
      setOptions(options.filter((_, i) => i !== index));
    }
  };

  const handleOptionRemove = (index) => () => {
    setOptions(options.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    const newPoll = await PChat.createPoll(
      question,
      options.filter((option) => option !== ''),
    );
    setQuestion('');
    setOptions(['']);
    setActivePolls((prevPolls) => [newPoll, ...prevPolls]);
  };

  const handleFinishActivePoll = async (pollId) => {
    const polls = await PChat.finishPoll(pollId);
    setActivePolls(polls.polls);
  };

  useEffect(() => {
    PChat.on(PChat.Events.CONNECT, async () => {
      const activePolls = await PChat.getPoll();
      setActivePolls(activePolls.polls);
    });

    PChat.on(PChat.Events.VOTE, (option) => {
      setActivePolls((prevPolls) => {
        return prevPolls.map((poll) => {
          if (poll.id === option.poll_id) {
            return {
              ...poll,
              options: poll.options.map((o) => {
                if (o.id === option.id) {
                  return { ...o, counter: option.counter };
                }
                return o;
              }),
            };
          }
          return poll;
        });
      });
    });
  }, []);

  return (
    <>
      <div className="poll-manager">
        <div className="room-status-block">New Poll</div>
        <textarea
          className="poll-question"
          value={question}
          onChange={handleQuestionChange}
          placeholder="Enter your question here"
        />
        {options.map((option, index) => (
          <div className="poll-option" key={index}>
            <input
              className="poll-input"
              type="text"
              value={option}
              onChange={handleOptionChange(index)}
              onBlur={handleOptionBlur(index)}
              placeholder={`Option ${index + 1}`}
              ref={(el) => (inputsRef.current[index] = el)}
            />
            {index !== options.length - 1 && (
              <button className="poll-remove" onClick={handleOptionRemove(index)}>
                -
              </button>
            )}
          </div>
        ))}
        <button className="button room-next-button" onClick={handleSubmit}>
          Publish
        </button>
      </div>
      <div className="poll-manager">
        {activePolls && (
          <>
            <div className="room-status-block">Active Polls ({activePolls.length})</div>
            {activePolls.map((poll) => (
              <div key={poll.id} className="poll">
                <div className="poll-question">
                  {poll.question}
                  <button className="poll-remove" onClick={() => handleFinishActivePoll(poll.id)}>
                    -
                  </button>
                </div>
                {poll.options.map((option) => (
                  <div key={option.id} className="poll-option">
                    <div className="poll-option-text">{option.data.text}</div>
                    <div className="poll-option-counter">{option.counter}</div>
                  </div>
                ))}
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}

export default PollManager;
